import { observer } from "mobx-react-lite";
import { getModuleConfigByModule, IntegrationCard, IntegrationCategory } from "../IntegrationsCommon";
import { Alert, Box, Button, FormHelperText, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import { FormAsyncAutocompleteField, FormCheckboxItem, FormRadioGroup, FormTextField, FormTextFileDropzone } from "../../../common/form/FormComponents";
import * as React from "react";
import { useEffect, useState } from "react";
import { ScreenContainer, ScreenTitleBar } from "../../layout/ScreenCommon";
import { IntegrationConfigInfo, IntegrationModule } from "gc-web-proto/galaxycompletepb/apipb/domainpb/integration_pb";
import { GalaxyMigrateDeploymentInfo } from "gc-web-proto/galaxycompletepb/apipb/domainpb/galaxymigrate_pb";
import { PagerParams } from "gc-web-proto/galaxycompletepb/commonpb/datafilter_pb";
import { IntegrationParams } from "gc-web-proto/galaxycompletepb/apipb/integration_api_pb";
import { DeploymentHostEnvironment } from "gc-web-proto/galaxycompletepb/apipb/domainpb/enumpb/deployment_host_environment_pb";
import { GalaxyMigrateAutoAllocationProgressDialog } from "../../cmcMigration/autoallocation/GmAutoAllocationProgress";
import * as yup from "yup";
import { useListIntegrationModules } from "../integration_hooks";
import { QueryResultWrapper } from "../../core/data/QueryResultWrapper";
import { useIntegrationRedirectState } from "../IntegrationRedirectState";
import { useGrpcApiStore } from "../../grpc/grpcApiStore";
import { ListGalaxyMigrateDeployments } from "gc-web-proto/galaxycompletepb/apipb/deployment_api_pb";
import { useCurrentProjectID } from "../../project/CurrentProjectState";
import Grid from "@mui/material/Grid2";

type EditCredentialsType = "yes" | "no";

interface FormFieldValues {
    name: string;
    deploymentId: string;
    module: IntegrationModule;
    connParams: ConnParamFields;
    editCredentials: EditCredentialsType;
    verboseLogging: boolean;
    skipWindowsMpioSetup: boolean;
}

type ConnParamFields =
    | AwsFields
    | AzureFields
    | DGSFields
    | PureFields
    | NetappFields
    | PowerMaxFields
    | PowerStoreFields
    | GcpFields
    | OracleFields
    | DigitalOceanFields
    | SilkFields
    | PowerFlexFields
    | VmwareDatastoreFields
    | NutanixFields
    | OvirtFields
    | KubernetesFields
    | CloudstackFields
    | OpenstackFields;

interface AwsFields {
    accessKey: string;
    secret: string;
    roleArn?: string;
    externalId?: string;
}

interface AzureFields {
    tenant: string;
    client: string;
    secret: string;
    subscriptionId: string;
}

interface DGSFields {
    username: string;
    password: string;
    endpoint: string;
}

interface PureFields {
    managementUri: string;
    apiToken: string;
}

interface NetappFields {
    username: string;
    password: string;
    endpoint: string;
}

interface PowerStoreFields {
    username: string;
    password: string;
    endpoint: string;
}

interface PowerMaxFields {
    username: string;
    password: string;
    endpoint: string;
    arrayId: string;
}

interface GcpFields {
    credentials: string;
}

interface OracleFields {
    ocid: string;
    fingerprint: string;
    privateKey: string;
    tenancy: string;
}

interface SilkFields {
    username: string;
    password: string;
    endpoint: string;
}

interface DigitalOceanFields {
    apiToken: string;
}

interface PowerFlexFields {
    username: string;
    password: string;
    endpoint: string;
    validateCert: boolean;
}

interface VmwareDatastoreFields {
    username: string;
    password: string;
    host: string;
    insecure: boolean;
}

interface AzureElasticSanFields {
    client: string;
    tenant: string;
    secret: string;
    sanRg: string;
    sanName: string;
    subscriptionId: string;
}

interface NutanixFields {
    username: string;
    password: string;
    endpoint: string;
    validateCert: boolean;
}

interface OvirtFields {
    host: string;
    username: string;
    password: string;
    validateCert: boolean;
}

interface KubernetesFields {
    endpoint: string;
    token: string;
    insecure: boolean;
    caCert: string;
    clientCert: string;
    clientKey: string;
    namespace: string;
}

interface CloudstackFields {
    endpoint: string;
    apiKey: string;
    apiSecret: string;
    domain: string;
    insecure: boolean;
}

interface OpenstackFields {
    username: string;
    password: string;
    auth_url: string;
    user_domain_name: string;
    project_name: string;
    region: string;
    insecure: boolean;
}

const INTEGRATION_PASSWORD = "integration-password";

// ======================
// IntegrationForm
// ======================

interface IntegrationFormProps {
    currentIntegration?: IntegrationConfigInfo.AsObject;
    newIntegrationType?: IntegrationModule;
    onCancel: () => void;
    onSubmit: (
        module: IntegrationModule,
        friendlyName: string,
        deploymentId?: string,
        connParams?: IntegrationParams.ConnParams,
        verboseLogging?: boolean
    ) => Promise<any>;
    onSubmitted?: () => void;
}

export const IntegrationForm: React.FC<IntegrationFormProps> = observer((p) => {
    const preselectedHost = useIntegrationRedirectState((s) => s.preselectedHost);

    const type = p.newIntegrationType || p.currentIntegration?.module;
    const integrationDefs = useListIntegrationModules();

    const [integrationModuleDef, setIntegrationModuleDef] = useState(getModuleConfigByModule(type, integrationDefs.data?.itemsList));

    useEffect(() => {
        if (integrationDefs.isFetched) {
            setIntegrationModuleDef(getModuleConfigByModule(type, integrationDefs.data?.itemsList));
        }
    }, [integrationDefs.isFetched, type, setIntegrationModuleDef, integrationDefs.data]);

    const skipWindowsMpioSetup = p.currentIntegration?.properties?.fieldsMap?.find((f) => f[0] === `skip_windows_mpio_setup`)[1]?.boolValue ?? false;
    const getInitialValues = (): FormFieldValues => {
        const fields = {
            name: p.currentIntegration?.friendlyName ?? "",
            deploymentId: preselectedHost ?? "",
            module: type,
            editCredentials: p.newIntegrationType ? ("yes" as EditCredentialsType) : ("no" as EditCredentialsType),
            verboseLogging: p.currentIntegration?.verboseLogging ?? false,
            skipWindowsMpioSetup: skipWindowsMpioSetup,
        };
        if (type === IntegrationModule.AWS) {
            const awsFields = Object.assign(fields, {
                connParams: {
                    accessKey: "",
                    secret: "",
                    roleArn: "",
                    externalId: "",
                },
            });
            return awsFields;
        } else if (type === IntegrationModule.AZURE) {
            const azureFields = Object.assign(fields, {
                connParams: {
                    tenant: "",
                    client: "",
                    secret: "",
                    subscriptionId: "",
                },
            });
            return azureFields;
        } else if (type === IntegrationModule.PURE || type === IntegrationModule.FC_PURE) {
            const pureFields = Object.assign(fields, {
                connParams: {
                    managementUri: "",
                    apiToken: "",
                },
            });
            return pureFields;
        } else if (type === IntegrationModule.DGS) {
            const dgsFields = Object.assign(fields, {
                connParams: {
                    username: "",
                    password: "",
                    endpoint: "",
                },
            });
            return dgsFields;
        } else if (type === IntegrationModule.NETAPP || type === IntegrationModule.AWS_FSX_NETAPP) {
            const netappFields = Object.assign(fields, {
                connParams: {
                    username: "",
                    password: "",
                    endpoint: "",
                },
            });
            return netappFields;
        } else if (type === IntegrationModule.POWERSTORE || type === IntegrationModule.FC_POWERSTORE) {
            const powerStoreFields = Object.assign(fields, {
                connParams: {
                    username: "",
                    password: "",
                    endpoint: "",
                },
            });
            return powerStoreFields;
        } else if (type === IntegrationModule.FC_POWERMAX) {
            const powerMaxFields = Object.assign(fields, {
                connParams: {
                    username: "",
                    password: "",
                    endpoint: "",
                    arrayId: "",
                },
            });
            return powerMaxFields;
        } else if (type === IntegrationModule.GCP) {
            const gcpFields = Object.assign(fields, {
                connParams: {
                    credentials: "",
                },
            });
            return gcpFields;
        } else if (type === IntegrationModule.ORACLE) {
            const oracleFields = Object.assign(fields, {
                connParams: {
                    ocid: "",
                    privateKey: "",
                    fingerprint: "",
                    tenancy: "",
                },
            });
            return oracleFields;
        } else if (type === IntegrationModule.SILKSDP) {
            const silkFields = Object.assign(fields, {
                connParams: {
                    username: "",
                    password: "",
                    endpoint: "",
                },
            });
            return silkFields;
        } else if (type === IntegrationModule.DIGITALOCEAN) {
            const digitalOceanFields = Object.assign(fields, {
                connParams: {
                    apiToken: "",
                },
            });
            return digitalOceanFields;
        } else if (type === IntegrationModule.POWERFLEX) {
            const powerFlexFields = Object.assign(fields, {
                connParams: {
                    username: "",
                    password: "",
                    endpoint: "",
                    validateCert: false,
                },
            });
            return powerFlexFields;
        } else if (type === IntegrationModule.AZURESAN) {
            const azureSanFields = Object.assign(fields, {
                connParams: {
                    client: "",
                    tenant: "",
                    secret: "",
                    sanRg: "",
                    sanName: "",
                    subscriptionId: "",
                },
            });
            return azureSanFields;
        } else if (type === IntegrationModule.VMWAREDATASTORE) {
            const vmwareDatastoreFields = Object.assign(fields, {
                connParams: {
                    username: "",
                    password: "",
                    host: "",
                    insecure: false,
                },
            });
            return vmwareDatastoreFields;
        } else if (type === IntegrationModule.NUTANIX) {
            const nutanixFields = Object.assign(fields, {
                connParams: {
                    username: "",
                    password: "",
                    endpoint: "",
                    validateCert: false,
                },
            });
            return nutanixFields;
        } else if (type === IntegrationModule.OVIRT) {
            const ovirtFields = Object.assign(fields, {
                connParams: {
                    host: "",
                    username: "",
                    password: "",
                    validateCert: false,
                },
            });
            return ovirtFields;
        } else if (type === IntegrationModule.KUBERNETES) {
            const kubernetesFields = Object.assign(fields, {
                connParams: {
                    endpoint: "",
                    token: "",
                    insecure: false,
                    caCert: "",
                    clientCert: "",
                    clientKey: "",
                    namespace: "default",
                },
            });
            return kubernetesFields;
        } else if (type === IntegrationModule.CLOUDSTACK) {
            const cloudstackFields = Object.assign(fields, {
                connParams: {
                    endpoint: "",
                    apiKey: "",
                    apiSecret: "",
                    domain: "",
                    insecure: false,
                },
            });
            return cloudstackFields;
        } else if (type === IntegrationModule.OPENSTACK) {
            const openstackFields = Object.assign(fields, {
                connParams: {
                    username: "",
                    password: "",
                    auth_url: "",
                    user_domain_name: "default",
                    project_name: "",
                    region: "",
                    insecure: false,
                },
            });
            return openstackFields;
        }
    };

    const skipWindowsMpioSettingSupported =
        integrationModuleDef.category === IntegrationCategory.FC_STORAGE_ARRAY || integrationModuleDef.category === IntegrationCategory.ISCSI_STORAGE_ARRAY;
    const validationSchema = yup.object({
        name: yup.string().required("Enter a name"),
        deploymentId: yup.string().notRequired(),
        verboseLogging: yup.boolean(),
        connParams: yup
            .mixed()
            .when(["module"], {
                is: IntegrationModule.AWS,
                then: yup.mixed().when(["editCredentials"], {
                    is: "yes",
                    then: yup.object({
                        accessKey: yup.string().required("Enter your access key"),
                        secret: yup.string().required("Enter the secret"),
                        roleArn: yup.string().notRequired(),
                        externalId: yup.string().notRequired(),
                    }),
                    otherwise: yup.object({
                        accessKey: yup.string().notRequired(),
                        secret: yup.string().notRequired(),
                        roleArn: yup.string().notRequired(),
                        externalId: yup.string().notRequired(),
                    }),
                }),
            })
            .when(["module"], {
                is: IntegrationModule.AZURE,
                then: yup.mixed().when("editCredentials", {
                    is: "yes",
                    then: yup.object({
                        tenant: yup.string().required("Enter the tenant ID"),
                        client: yup.string().required("Enter the client ID"),
                        secret: yup.string().required("Enter the secret"),
                        subscriptionId: yup.string().required("Enter the subscription ID"),
                    }),
                    otherwise: yup.object({
                        tenant: yup.string().notRequired(),
                        client: yup.string().notRequired(),
                        secret: yup.string().notRequired(),
                        subscriptionId: yup.string().notRequired(),
                    }),
                }),
            })
            .when(["module"], {
                is: IntegrationModule.DGS,
                then: yup.mixed().when("editCredentials", {
                    is: "yes",
                    then: yup.object({
                        username: yup.string().required("Enter your username"),
                        password: yup.string().required("Enter your password"),
                        endpoint: yup.string().required("Enter the server address"),
                    }),
                    otherwise: yup.object({
                        username: yup.string().notRequired(),
                        password: yup.string().notRequired(),
                        endpoint: yup.string().notRequired(),
                    }),
                }),
            })
            .when(["module"], {
                is: IntegrationModule.PURE || IntegrationModule.FC_PURE,
                then: yup.mixed().when(["editCredentials"], {
                    is: "yes",
                    then: yup.object({
                        managementUri: yup.string().required("Enter the management URI"),
                        apiToken: yup.string().required("Enter your API token"),
                    }),
                    otherwise: yup.object({
                        managementUri: yup.string().notRequired(),
                        apiToken: yup.string().notRequired(),
                    }),
                }),
            })
            .when(["module"], {
                is: IntegrationModule.NETAPP || IntegrationModule.AWS_FSX_NETAPP,
                then: yup.mixed().when("editCredentials", {
                    is: "yes",
                    then: yup.object({
                        username: yup.string().required("Enter your username"),
                        password: yup.string().required("Enter your password"),
                        endpoint: yup.string().required("Enter the server address"),
                    }),
                    otherwise: yup.object({
                        username: yup.string().notRequired(),
                        password: yup.string().notRequired(),
                        endpoint: yup.string().notRequired(),
                    }),
                }),
            })
            .when(["module"], {
                is: IntegrationModule.FC_POWERSTORE || IntegrationModule.POWERSTORE,
                then: yup.mixed().when("editCredentials", {
                    is: "yes",
                    then: yup.object({
                        username: yup.string().required("Enter your username"),
                        password: yup.string().required("Enter your password"),
                        endpoint: yup.string().required("Enter the server address"),
                    }),
                    otherwise: yup.object({
                        username: yup.string().notRequired(),
                        password: yup.string().notRequired(),
                        endpoint: yup.string().notRequired(),
                    }),
                }),
            })
            .when(["module"], {
                is: IntegrationModule.FC_POWERMAX,
                then: yup.mixed().when("editCredentials", {
                    is: "yes",
                    then: yup.object({
                        username: yup.string().required("Enter your username"),
                        password: yup.string().required("Enter your password"),
                        endpoint: yup.string().required("Enter the server address"),
                        arrayId: yup.string().required("Enter the array ID"),
                    }),
                    otherwise: yup.object({
                        username: yup.string().notRequired(),
                        password: yup.string().notRequired(),
                        endpoint: yup.string().notRequired(),
                        arrayId: yup.string().notRequired(),
                    }),
                }),
            })
            .when(["module"], {
                is: IntegrationModule.GCP,
                then: yup.mixed().when("editCredentials", {
                    is: "yes",
                    then: yup.object({
                        credentials: yup.string().required("Enter your credentials"),
                    }),
                    otherwise: yup.object({
                        credentials: yup.string().notRequired(),
                    }),
                }),
            })
            .when(["module"], {
                is: IntegrationModule.ORACLE,
                then: yup.mixed().when("editCredentials", {
                    is: "yes",
                    then: yup.object({
                        ocid: yup.string().required("Enter the OCID"),
                        fingerprint: yup.string().required("Enter the fingerprint"),
                        privateKey: yup.string().required("Add the private key"),
                        tenancy: yup.string().required("Enter the tenancy"),
                    }),
                    otherwise: yup.object({
                        ocid: yup.string().notRequired(),
                        fingerprint: yup.string().notRequired(),
                        privateKey: yup.string().notRequired(),
                        tenancy: yup.string().notRequired(),
                    }),
                }),
            })
            .when(["module"], {
                is: IntegrationModule.SILKSDP,
                then: yup.mixed().when("editCredentials", {
                    is: "yes",
                    then: yup.object({
                        username: yup.string().required("Enter your username"),
                        password: yup.string().required("Enter your password"),
                        endpoint: yup.string().required("Enter the server address"),
                    }),
                    otherwise: yup.object({
                        username: yup.string().notRequired(),
                        password: yup.string().notRequired(),
                        endpoint: yup.string().notRequired(),
                    }),
                }),
            })
            .when(["module"], {
                is: IntegrationModule.DIGITALOCEAN,
                then: yup.mixed().when("editCredentials", {
                    is: "yes",
                    then: yup.object({
                        apiToken: yup.string().required("Enter your token"),
                    }),
                    otherwise: yup.object({
                        apiToken: yup.string().notRequired(),
                    }),
                }),
            })
            .when(["module"], {
                is: IntegrationModule.POWERFLEX,
                then: yup.mixed().when("editCredentials", {
                    is: "yes",
                    then: yup.object({
                        username: yup.string().required("Enter your username"),
                        password: yup.string().required("Enter your password"),
                        endpoint: yup.string().required("Enter the server address"),
                    }),
                    otherwise: yup.object({
                        username: yup.string().notRequired(),
                        password: yup.string().notRequired(),
                        endpoint: yup.string().notRequired(),
                    }),
                }),
            })
            .when(["module"], {
                is: IntegrationModule.AZURESAN,
                then: yup.mixed().when("editCredentials", {
                    is: "yes",
                    then: yup.object({
                        tenant: yup.string().required("Enter the tenant ID"),
                        client: yup.string().required("Enter the client ID"),
                        secret: yup.string().required("Enter the secret"),
                        sanRg: yup.string().required("Enter the resource group name"),
                        sanName: yup.string().required("Enter the SAN name"),
                        subscriptionId: yup.string().required("Enter the subscription ID"),
                    }),
                    otherwise: yup.object({
                        tenant: yup.string().notRequired(),
                        client: yup.string().notRequired(),
                        secret: yup.string().notRequired(),
                        sanRg: yup.string().notRequired(),
                        sanName: yup.string().notRequired(),
                        subscriptionId: yup.string().notRequired(),
                    }),
                }),
            })
            .when(["module"], {
                is: IntegrationModule.VMWAREDATASTORE,
                then: yup.mixed().when("editCredentials", {
                    is: "yes",
                    then: yup.object({
                        username: yup.string().required("Enter your username"),
                        password: yup.string().required("Enter your password"),
                        host: yup.string().required("Enter the host"),
                        insecure: yup.boolean().notRequired(),
                    }),
                    otherwise: yup.object({
                        username: yup.string().notRequired(),
                        password: yup.string().notRequired(),
                        host: yup.string().notRequired(),
                        insecure: yup.boolean().notRequired(),
                    }),
                }),
            })
            .when(["module"], {
                is: IntegrationModule.NUTANIX,
                then: yup.mixed().when("editCredentials", {
                    is: "yes",
                    then: yup.object({
                        username: yup.string().required("Enter your username"),
                        password: yup.string().required("Enter your password"),
                        endpoint: yup.string().required("Enter the server address"),
                    }),
                    otherwise: yup.object({
                        username: yup.string().notRequired(),
                        password: yup.string().notRequired(),
                        endpoint: yup.string().notRequired(),
                    }),
                }),
            })
            .when(["module"], {
                is: IntegrationModule.OVIRT,
                then: yup.mixed().when("editCredentials", {
                    is: "yes",
                    then: yup.object({
                        host: yup.string().required("Enter the host"),
                        username: yup.string().required("Enter your username").matches(/@/, {
                            message: "Username must contain at least one @ sign (format should be admin@internal))",
                        }),
                        password: yup.string().required("Enter your password"),
                    }),
                    otherwise: yup.object({
                        host: yup.string().notRequired(),
                        username: yup.string().notRequired(),
                        password: yup.string().notRequired(),
                    }),
                }),
            })
            .when(["module"], {
                is: IntegrationModule.KUBERNETES,
                then: yup.mixed().when("editCredentials", {
                    is: "yes",
                    then: yup.object({
                        endpoint: yup.string().required("Enter the endpoint."),
                        validateCert: yup.boolean(),
                        caCert: yup.string().notRequired(),
                        clientCert: yup.string(),
                        clientKey: yup.string(),
                        token: yup.string(),
                        namespace: yup.string(),
                    }),
                    otherwise: yup.object({
                        endpoint: yup.string().required("Enter the endpoint."),
                        validateCert: yup.boolean(),
                        caCert: yup.string().notRequired(),
                        clientCert: yup.string().notRequired(),
                        clientKey: yup.string().notRequired(),
                        token: yup.string().notRequired(),
                        namespace: yup.string().required("Enter the namespace."),
                    }),
                }),
            })
            .when(["module"], {
                is: IntegrationModule.CLOUDSTACK,
                then: yup.mixed().when("editCredentials", {
                    is: "yes",
                    then: yup.object({
                        endpoint: yup.string().required("Enter the endpoint."),
                        apiKey: yup.string().required("Enter the API key."),
                        apiSecret: yup.string().required("Enter the API secret."),
                        domain: yup.string().notRequired(),
                        insecure: yup.boolean().notRequired(),
                    }),
                }),
            })
            .when(["module"], {
                is: IntegrationModule.OPENSTACK,
                then: yup.mixed().when("editCredentials", {
                    is: "yes",
                    then: yup.object({
                        username: yup.string().notRequired(),
                        password: yup.string().notRequired(),
                        auth_url: yup.string().notRequired(),
                        user_domain_name: yup.string().notRequired(),
                        project_name: yup.string().notRequired(),
                        region: yup.string().notRequired(),
                        insecure: yup.boolean().notRequired(),
                    }),
                    otherwise: yup.object({
                        username: yup.string().notRequired(),
                        password: yup.string().notRequired(),
                        auth_url: yup.string().notRequired(),
                        user_domain_name: yup.string().notRequired(),
                        project_name: yup.string().notRequired(),
                        region: yup.string().notRequired(),
                        insecure: yup.boolean().notRequired(),
                    }),
                }),
            }),
    });

    return (
        <ScreenContainer>
            <ScreenTitleBar title={!p.currentIntegration ? `Add Integration` : "Edit Integration"} />
            <Typography variant={"body2"}>You will be able to use this integration within this project.</Typography>
            <br />
            <QueryResultWrapper queryResult={integrationDefs}>
                <Grid container spacing={4}>
                    <Grid>
                        <IntegrationCard module={integrationModuleDef} cardProps={{ sx: { width: 325 } }} />
                    </Grid>
                    <Grid>
                        <Formik
                            initialValues={getInitialValues()}
                            validationSchema={validationSchema}
                            onSubmit={async (values) => {
                                const connParams = getConnParamsByModule(values);
                                connParams.setSkipWindowsMpioSetup(values.skipWindowsMpioSetup);
                                connParams.setVerboseLogging(values.verboseLogging);
                                await p.onSubmit(type, values.name, values.deploymentId, connParams, values.verboseLogging);

                                if (!!p.onSubmitted) {
                                    p.onSubmitted();
                                }
                            }}
                        >
                            {(props) => {
                                return (
                                    <Form>
                                        <Box pb={2}>
                                            <Typography variant={"h5"}>1. General Information</Typography>
                                            <br />
                                            <FormTextField label={"Name"} name={"name"} required />
                                            <FormHelperText component={"span"}>{"Integration name"}</FormHelperText>
                                            <br />
                                            <Alert severity={"info"}>
                                                {" "}
                                                Use a descriptive name as you will not be able to retrieve other connection parameters set here once they are
                                                encrypted and stored
                                            </Alert>
                                        </Box>
                                        {!!p.currentIntegration && (
                                            <Box pb={2} pt={2}>
                                                <SelectEditCredentials />
                                            </Box>
                                        )}
                                        {(!!p.newIntegrationType || props.values.editCredentials === "yes") && (
                                            <>
                                                <Box pb={2}>
                                                    <Typography variant={"h5"}>2. Credentials</Typography>
                                                    <br />
                                                    {renderConnectionParamFields(type, true)}
                                                </Box>
                                                {!preselectedHost && (
                                                    <Box pb={2}>
                                                        <Typography variant={"h5"}>{`3. Verify Connection From Host (Optional)`}</Typography>
                                                        <br />
                                                        <VerifyIntegrationConnection />
                                                    </Box>
                                                )}
                                            </>
                                        )}
                                        <Box>
                                            <FormCheckboxItem
                                                label={"Verbose Logging"}
                                                name={"verboseLogging"}
                                                helperText={
                                                    "If enabled, all interactions between the integration and the vendor will be logged. This should only be used for troubleshooting purposes"
                                                }
                                            />
                                        </Box>
                                        {!!skipWindowsMpioSettingSupported && (
                                            <Box pt={2}>
                                                <FormCheckboxItem
                                                    label={"Skip Windows MPIO Auto Configuration"}
                                                    name={"skipWindowsMpioSetup"}
                                                    helperText={
                                                        "If enabled, Windows MPIO configuration validation and remediation will not be done automatically during auto allocation. Only applicable to CMC v5.7.0+."
                                                    }
                                                />
                                            </Box>
                                        )}
                                        <Box display={"flex"} pt={2}>
                                            <Box pr={2}>
                                                <Button variant={"contained"} color={"primary"} type={"submit"}>
                                                    Save
                                                </Button>
                                            </Box>
                                            <Box>
                                                <Button variant={"outlined"} onClick={p.onCancel}>
                                                    Cancel
                                                </Button>
                                            </Box>
                                        </Box>
                                    </Form>
                                );
                            }}
                        </Formik>
                    </Grid>
                </Grid>
            </QueryResultWrapper>

            <GalaxyMigrateAutoAllocationProgressDialog actionLabel={"Close"} />
        </ScreenContainer>
    );
});

interface ConnectionParamProps {
    required: boolean;
}

// ======================
// AwsConnectionParams
// ======================

export const AwsConnectionParams: React.FC<ConnectionParamProps> = observer((p) => {
    return (
        <>
            <Grid container spacing={3}>
                <Grid
                    size={{
                        xs: 12,
                        sm: 6,
                    }}
                >
                    <FormTextField name={"connParams.accessKey"} label={"Access Key"} variant={"filled"} required={p.required} />
                    <FormHelperText component={"span"}>{"Your AWS access key"}</FormHelperText>
                </Grid>
                <Grid
                    size={{
                        xs: 12,
                        sm: 6,
                    }}
                >
                    <FormTextField
                        name={"connParams.secret"}
                        label={"Secret"}
                        variant={"filled"}
                        required={p.required}
                        type={"text"}
                        onFocus={(e) => {
                            document.getElementById(INTEGRATION_PASSWORD).setAttribute("type", "password");
                        }}
                        id={INTEGRATION_PASSWORD}
                    />
                </Grid>
                <Grid
                    size={{
                        xs: 12,
                        sm: 6,
                    }}
                >
                    <FormTextField name={"connParams.roleArn"} label={"Role ARN"} variant={"filled"} />
                    <FormHelperText component={"span"}>{"If specified, the role will be assumed before making any calls."}</FormHelperText>
                </Grid>
                <Grid
                    size={{
                        xs: 12,
                        sm: 6,
                    }}
                >
                    <FormTextField name={"connParams.externalId"} label={"External ID"} variant={"filled"} />
                    <FormHelperText component={"span"}>
                        {"If specified, this external ID will be used when assuming role. Only applicable when Role ARN is specified."}
                    </FormHelperText>
                </Grid>
            </Grid>
        </>
    );
});

// ======================
// AzureConnectionParams
// ======================

export const AzureConnectionParams: React.FC<ConnectionParamProps> = observer((p) => {
    return (
        <>
            <Grid container spacing={3}>
                <Grid
                    size={{
                        xs: 12,
                        sm: 6,
                    }}
                >
                    <FormTextField name={"connParams.tenant"} label={"Tenant ID"} variant={"filled"} required={p.required} />
                    <FormHelperText>{"Tenant ID from your Azure Active Directory"}</FormHelperText>
                </Grid>
                <Grid
                    size={{
                        xs: 12,
                        sm: 6,
                    }}
                >
                    <FormTextField name={"connParams.subscriptionId"} label={"Subscription ID"} variant={"filled"} required={p.required} />
                    <FormHelperText>{"Your Subscription ID"}</FormHelperText>
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid
                    size={{
                        xs: 12,
                        sm: 6,
                    }}
                >
                    <FormTextField name={"connParams.client"} label={"Client/Application ID"} variant={"filled"} required={p.required} />
                </Grid>
                <Grid
                    size={{
                        xs: 12,
                        sm: 6,
                    }}
                >
                    <FormTextField
                        name={"connParams.secret"}
                        label={"Secret"}
                        type={"text"}
                        onFocus={(e) => {
                            document.getElementById(INTEGRATION_PASSWORD).setAttribute("type", "password");
                        }}
                        variant={"filled"}
                        required={p.required}
                        id={INTEGRATION_PASSWORD}
                    />
                </Grid>
            </Grid>
        </>
    );
});

// ======================
// AzureElasticSanConnectionParams
// ======================

export const AzureElasticSanConnectionParams: React.FC<ConnectionParamProps> = observer((p) => {
    return (
        <>
            <Grid container spacing={3}>
                <Grid
                    size={{
                        xs: 12,
                        sm: 6,
                    }}
                >
                    <FormTextField name={"connParams.tenant"} label={"Tenant ID"} variant={"filled"} required={p.required} />
                    <FormHelperText>{"Tenant ID from your Azure Active Directory"}</FormHelperText>
                </Grid>
                <Grid
                    size={{
                        xs: 12,
                        sm: 6,
                    }}
                >
                    <FormTextField name={"connParams.subscriptionId"} label={"Subscription ID"} variant={"filled"} required={p.required} />
                    <FormHelperText>{"Your Subscription ID"}</FormHelperText>
                </Grid>
            </Grid>
            <Grid container spacing={3} pt={2}>
                <Grid
                    size={{
                        xs: 12,
                        sm: 6,
                    }}
                >
                    <FormTextField name={"connParams.client"} label={"Client/Application ID"} variant={"filled"} required={p.required} />
                </Grid>
                <Grid
                    size={{
                        xs: 12,
                        sm: 6,
                    }}
                >
                    <FormTextField
                        name={"connParams.secret"}
                        label={"Secret"}
                        type={"text"}
                        onFocus={(e) => {
                            document.getElementById(INTEGRATION_PASSWORD).setAttribute("type", "password");
                        }}
                        variant={"filled"}
                        required={p.required}
                        id={INTEGRATION_PASSWORD}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={3} pt={2}>
                <Grid
                    size={{
                        xs: 12,
                        sm: 6,
                    }}
                >
                    <FormTextField name={"connParams.sanRg"} label={"SAN Resource Group"} variant={"filled"} required={p.required} />
                </Grid>
                <Grid
                    size={{
                        xs: 12,
                        sm: 6,
                    }}
                >
                    <FormTextField name={"connParams.sanName"} label={"SAN Name"} variant={"filled"} required={p.required} />
                </Grid>
            </Grid>
        </>
    );
});

// ======================
// DGSConnectionParams
// ======================

export const DGSConnectionParams: React.FC<ConnectionParamProps> = observer((p) => {
    return (
        <>
            <Grid container spacing={3}>
                <Grid size={12}>
                    <FormTextField name={"connParams.endpoint"} label={"Server Address"} variant={"filled"} required={p.required} />
                    <FormHelperText>{"Server Address to connect to"}</FormHelperText>
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid
                    size={{
                        xs: 12,
                        sm: 6,
                    }}
                >
                    <FormTextField name={"connParams.username"} label={"Username"} variant={"filled"} required={p.required} />
                    <FormHelperText>{"Username"}</FormHelperText>
                </Grid>
                <Grid
                    size={{
                        xs: 12,
                        sm: 6,
                    }}
                >
                    <FormTextField
                        name={"connParams.password"}
                        label={"Password"}
                        variant={"filled"}
                        required={p.required}
                        type={"text"}
                        onFocus={(e) => {
                            document.getElementById(INTEGRATION_PASSWORD).setAttribute("type", "password");
                        }}
                        id={INTEGRATION_PASSWORD}
                    />
                </Grid>
            </Grid>
        </>
    );
});

// ======================
// NetAppConnectionParams
// ======================

export const NetappConnectionParams: React.FC<ConnectionParamProps> = observer((p) => {
    return (
        <>
            <Grid container spacing={3}>
                <Grid size={12}>
                    <FormTextField name={"connParams.endpoint"} label={"Server Address"} variant={"filled"} required={p.required} />
                    <FormHelperText>{"Server Address to connect to"}</FormHelperText>
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid
                    size={{
                        xs: 12,
                        sm: 6,
                    }}
                >
                    <FormTextField name={"connParams.username"} label={"Username"} variant={"filled"} required={p.required} />
                    <FormHelperText>{"Username"}</FormHelperText>
                </Grid>
                <Grid
                    size={{
                        xs: 12,
                        sm: 6,
                    }}
                >
                    <FormTextField
                        name={"connParams.password"}
                        label={"Password"}
                        variant={"filled"}
                        required={p.required}
                        id={INTEGRATION_PASSWORD}
                        type={"text"}
                        onFocus={(e) => {
                            document.getElementById(INTEGRATION_PASSWORD).setAttribute("type", "password");
                        }}
                    />
                </Grid>
            </Grid>
        </>
    );
});

// ======================
// PureConnectionParams
// ======================

export const PureConnectionParams: React.FC<ConnectionParamProps> = observer((p) => {
    return (
        <>
            <Grid container spacing={3}>
                <Grid
                    size={{
                        xs: 12,
                        sm: 6,
                    }}
                >
                    <FormTextField name={"connParams.managementUri"} label={"Management Address"} variant={"filled"} required={p.required} />
                    <FormHelperText>{"Host / IP to access storage management APIs"}</FormHelperText>
                </Grid>
                <Grid
                    size={{
                        xs: 12,
                        sm: 6,
                    }}
                >
                    <FormTextField name={"connParams.apiToken"} label={"FlashArray User API Token"} variant={"filled"} required={p.required} />
                </Grid>
            </Grid>
        </>
    );
});

// ======================
// PowerStoreConnectionParams
// ======================

export const PowerStoreConnectionParams: React.FC<ConnectionParamProps> = observer((p) => {
    return (
        <>
            <Grid container spacing={3}>
                <Grid size={12}>
                    <FormTextField name={"connParams.endpoint"} label={"Server Address"} variant={"filled"} required={p.required} />
                    <FormHelperText>{"Server Address to connect to"}</FormHelperText>
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid
                    size={{
                        xs: 12,
                        sm: 6,
                    }}
                >
                    <FormTextField name={"connParams.username"} label={"Username"} variant={"filled"} required={p.required} />
                    <FormHelperText>{"Username"}</FormHelperText>
                </Grid>
                <Grid
                    size={{
                        xs: 12,
                        sm: 6,
                    }}
                >
                    <FormTextField
                        name={"connParams.password"}
                        id={INTEGRATION_PASSWORD}
                        label={"Password"}
                        variant={"filled"}
                        required={p.required}
                        type={"text"}
                        onFocus={(e) => {
                            document.getElementById(INTEGRATION_PASSWORD).setAttribute("type", "password");
                        }}
                    />
                </Grid>
            </Grid>
        </>
    );
});

// ======================
// PowerMaxConnectionParams
// ======================

export const PowerMaxConnectionParams: React.FC<ConnectionParamProps> = observer((p) => {
    return (
        <>
            <Grid container spacing={3}>
                <Grid
                    size={{
                        xs: 12,
                        sm: 6,
                    }}
                >
                    <FormTextField name={"connParams.endpoint"} label={"Server Address"} variant={"filled"} required={p.required} />
                    <FormHelperText>{"Server Address to connect to"}</FormHelperText>
                </Grid>
                <Grid
                    size={{
                        xs: 12,
                        sm: 6,
                    }}
                >
                    <FormTextField name={"connParams.arrayId"} label={"Array ID"} variant={"filled"} required={p.required} />
                    <FormHelperText>{"Array ID"}</FormHelperText>
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid
                    size={{
                        xs: 12,
                        sm: 6,
                    }}
                >
                    <FormTextField name={"connParams.username"} label={"Username"} variant={"filled"} required={p.required} />
                    <FormHelperText>{"Username"}</FormHelperText>
                </Grid>
                <Grid
                    size={{
                        xs: 12,
                        sm: 6,
                    }}
                >
                    <FormTextField
                        name={"connParams.password"}
                        id={INTEGRATION_PASSWORD}
                        label={"Password"}
                        variant={"filled"}
                        required={p.required}
                        type={"text"}
                        onFocus={(e) => {
                            document.getElementById(INTEGRATION_PASSWORD).setAttribute("type", "password");
                        }}
                    />
                </Grid>
            </Grid>
        </>
    );
});

// ======================
// GcpConnectionParams
// ======================

export const GcpConnectionParams: React.FC<ConnectionParamProps> = observer((p) => {
    return (
        <>
            <Typography>Upload your service account private key file.</Typography>
            <br />
            <FormTextFileDropzone label={"Credentials"} name={"connParams.credentials"} maxFiles={1} accept={[".json"]} />
        </>
    );
});

// ======================
// OracleConnectionParams
// ======================

export const OracleConnectionParams: React.FC<ConnectionParamProps> = observer((p) => {
    return (
        <>
            <Grid container spacing={3}>
                <Grid
                    size={{
                        xs: 12,
                        sm: 6,
                    }}
                >
                    <FormTextField name={"connParams.ocid"} label={"OCID"} variant={"filled"} required={p.required} />
                    <FormHelperText>{"OCID"}</FormHelperText>
                </Grid>
                <Grid
                    size={{
                        xs: 12,
                        sm: 6,
                    }}
                >
                    <FormTextField name={"connParams.fingerprint"} label={"Fingerprint"} variant={"filled"} required={p.required} />
                    <FormHelperText>{"Fingerprint"}</FormHelperText>
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid
                    size={{
                        xs: 12,
                        sm: 6,
                    }}
                >
                    <FormTextField name={"connParams.tenancy"} label={"Tenancy"} variant={"filled"} required={p.required} />
                    <FormHelperText>{"Tenancy"}</FormHelperText>
                </Grid>
            </Grid>
            <Box pt={2}>
                <Typography>Upload your private key file.</Typography>
                <br />
                <FormTextFileDropzone label={"Private Key"} name={"connParams.privateKey"} maxFiles={1} accept={[".pem"]} />
            </Box>
        </>
    );
});

// ======================
// SilkSdpConnectionParams
// ======================

export const SilkConnectionParams: React.FC<ConnectionParamProps> = observer((p) => {
    return (
        <>
            <Grid container spacing={3}>
                <Grid size={12}>
                    <FormTextField name={"connParams.endpoint"} label={"Server Address"} variant={"filled"} required={p.required} />
                    <FormHelperText>{"Server Address to connect to"}</FormHelperText>
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid
                    size={{
                        xs: 12,
                        sm: 6,
                    }}
                >
                    <FormTextField name={"connParams.username"} label={"Username"} variant={"filled"} required={p.required} />
                    <FormHelperText>{"Username"}</FormHelperText>
                </Grid>
                <Grid
                    size={{
                        xs: 12,
                        sm: 6,
                    }}
                >
                    <FormTextField
                        name={"connParams.password"}
                        label={"Password"}
                        id={INTEGRATION_PASSWORD}
                        variant={"filled"}
                        required={p.required}
                        type={"text"}
                        onFocus={(e) => {
                            document.getElementById(INTEGRATION_PASSWORD).setAttribute("type", "password");
                        }}
                    />
                </Grid>
            </Grid>
        </>
    );
});

// ======================
// DigitalOceanConnectionParams
// ======================

export const DigitalOceanConnectionParams: React.FC<ConnectionParamProps> = observer((p) => {
    return (
        <>
            <Grid container spacing={3}>
                <Grid size={12}>
                    <FormTextField name={"connParams.apiToken"} label={"Token"} variant={"filled"} required={p.required} />
                    <FormHelperText>{"Your token"}</FormHelperText>
                </Grid>
            </Grid>
        </>
    );
});

// ======================
// PowerflexConnParams
// ======================

export const PowerflexConnParams: React.FC<ConnectionParamProps> = observer((p) => {
    return (
        <>
            <Grid container spacing={3}>
                <Grid size={12}>
                    <FormTextField name={"connParams.endpoint"} label={"Server Address"} variant={"filled"} required={p.required} />
                    <FormHelperText>{"Server Address to connect to"}</FormHelperText>
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid
                    size={{
                        xs: 12,
                        sm: 6,
                    }}
                >
                    <FormTextField name={"connParams.username"} label={"Username"} variant={"filled"} required={p.required} />
                    <FormHelperText>{"Username"}</FormHelperText>
                </Grid>
                <Grid
                    size={{
                        xs: 12,
                        sm: 6,
                    }}
                >
                    <FormTextField
                        name={"connParams.password"}
                        id={INTEGRATION_PASSWORD}
                        label={"Password"}
                        variant={"filled"}
                        required={p.required}
                        type={"text"}
                        onFocus={(e) => {
                            document.getElementById(INTEGRATION_PASSWORD).setAttribute("type", "password");
                        }}
                    />
                </Grid>
            </Grid>
            <FormCheckboxItem label={"Validate Server Certificates"} name={"connParams.insecure"} required={p.required} />
        </>
    );
});

// ======================
// VmwareDatastoreConnParams
// ======================

export const VmwareDatastoreConnParams: React.FC<ConnectionParamProps> = observer((p) => {
    return (
        <>
            <Grid container spacing={3} pb={1}>
                <Grid size={12}>
                    <FormTextField name={"connParams.host"} label={"vCenter / vSphere Management Address"} variant={"filled"} required={p.required} />
                    <FormHelperText>{"vCenter / vSphere Management Address"}</FormHelperText>
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid
                    size={{
                        xs: 12,
                        sm: 6,
                    }}
                >
                    <FormTextField name={"connParams.username"} label={"Username"} variant={"filled"} required={p.required} />
                    <FormHelperText>{"Username"}</FormHelperText>
                </Grid>
                <Grid
                    size={{
                        xs: 12,
                        sm: 6,
                    }}
                >
                    <FormTextField
                        name={"connParams.password"}
                        id={INTEGRATION_PASSWORD}
                        label={"Password"}
                        variant={"filled"}
                        required={p.required}
                        type={"text"}
                        onFocus={(e) => {
                            document.getElementById(INTEGRATION_PASSWORD).setAttribute("type", "password");
                        }}
                    />
                </Grid>
                <Grid size={12}>
                    <FormCheckboxItem label={"Allow Insecure Connection"} name={"connParams.insecure"} />
                </Grid>
            </Grid>
        </>
    );
});

// ======================
// NutanixConnParams
// ======================

export const NutanixConnParams: React.FC<ConnectionParamProps> = observer((p) => {
    return (
        <>
            <Grid container spacing={3}>
                <Grid size={12}>
                    <FormTextField name={"connParams.endpoint"} label={"Server Address"} variant={"filled"} required={p.required} />
                    <FormHelperText>{"Server Address to connect to (format: 'https://[ip-address]:9440')"}</FormHelperText>
                </Grid>
            </Grid>
            <Grid container spacing={3} mt={1}>
                <Grid
                    size={{
                        xs: 12,
                        sm: 6,
                    }}
                >
                    <FormTextField name={"connParams.username"} label={"Prism Element Username"} variant={"filled"} required={p.required} />
                    <FormHelperText>{"Prism Element Username"}</FormHelperText>
                </Grid>
                <Grid
                    size={{
                        xs: 12,
                        sm: 6,
                    }}
                >
                    <FormTextField
                        name={"connParams.password"}
                        label={"Password"}
                        variant={"filled"}
                        required={p.required}
                        id={INTEGRATION_PASSWORD}
                        type={"text"}
                        onFocus={(e) => {
                            document.getElementById(INTEGRATION_PASSWORD).setAttribute("type", "password");
                        }}
                    />
                </Grid>
            </Grid>
            <FormCheckboxItem label={"Validate Server Certificates"} name={"connParams.validateCert"} required={p.required} />
        </>
    );
});

// ======================
// OvirtConnParams
// ======================

export const OvirtConnParams: React.FC<ConnectionParamProps> = (p) => {
    return (
        <>
            <Grid container spacing={3} pb={1}>
                <Grid size={12}>
                    <FormTextField name={"connParams.host"} label={"Host Address"} variant={"filled"} required={p.required} />
                    <FormHelperText>{"The name/ip of the oVirt Engine host"}</FormHelperText>
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid
                    size={{
                        xs: 12,
                        sm: 6,
                    }}
                >
                    <FormTextField name={"connParams.username"} label={"Username"} variant={"filled"} required={p.required} />
                    <FormHelperText>{`Username (e.g. "admin@internal")`}</FormHelperText>
                </Grid>
                <Grid
                    size={{
                        xs: 12,
                        sm: 6,
                    }}
                >
                    <FormTextField
                        name={"connParams.password"}
                        id={INTEGRATION_PASSWORD}
                        label={"Password"}
                        variant={"filled"}
                        required={p.required}
                        type={"text"}
                        onFocus={(e) => {
                            document.getElementById(INTEGRATION_PASSWORD).setAttribute("type", "password");
                        }}
                    />
                </Grid>
                <Grid size={12}>
                    <FormCheckboxItem label={"Validate Server Certificates"} name={"connParams.insecure"} />
                </Grid>
            </Grid>
        </>
    );
};

// ======================
// KubernetesConnParams
// ======================
export const KubernetesConnParams: React.FC<ConnectionParamProps> = (p) => {
    return (
        <>
            <Grid container spacing={3} pb={1}>
                <Grid size={12}>
                    <FormTextField name={"connParams.endpoint"} label={"Endpoint"} variant={"filled"} required={p.required} />
                    <FormHelperText>{"Endpoint to your Kubernetes API server (e.g. https://k8s.example.com:6443)"}</FormHelperText>
                </Grid>
                <Grid size={12}>
                    <FormTextField name={"connParams.namespace"} label={"Default Namespace"} variant={"filled"} required={p.required} />
                    <FormHelperText>{"Namespace in the kubernetes cluster to access."}</FormHelperText>
                </Grid>
            </Grid>
            <Box pt={1} pb={1}>
                <Typography>{"Enter the relevant authentication fields, whether the token, the client cert/key combination, or both."}</Typography>
            </Box>
            <Grid container spacing={3} pb={1}>
                <Grid size={12}>
                    <FormTextField multiline minRows={2} name={"connParams.token"} label={"Token"} variant={"filled"} required={false} />
                    <FormHelperText>{"Authentication token for the Kubernetes cluster (e.g. service account token or bearer token)"}</FormHelperText>
                </Grid>
                <Grid
                    size={{
                        xs: 12,
                    }}
                >
                    <FormTextField multiline minRows={2} name={"connParams.clientCert"} label={"Client Certificate"} variant={"filled"} required={false} />
                    <FormHelperText>{`Client certificate in PEM format for mTLS authentication`}</FormHelperText>
                </Grid>
                <Grid
                    size={{
                        xs: 12,
                    }}
                >
                    <FormTextField multiline minRows={2} name={"connParams.clientKey"} label={"Client Key"} variant={"filled"} required={false} type={"text"} />
                    <FormHelperText>{`Client private key in PEM format for mTLS authentication`}</FormHelperText>
                </Grid>
                <Grid size={12}>
                    <FormTextField
                        multiline
                        minRows={2}
                        fullWidth
                        name={"connParams.caCert"}
                        label={"CA Certificate (Optional)"}
                        variant={"filled"}
                        required={false}
                    />
                    <FormHelperText>{"CA certificate in PEM format for validating the API server's TLS certificate"}</FormHelperText>
                </Grid>
                <Grid size={12}>
                    <FormCheckboxItem label={"Allow Insecure Connection"} name={"connParams.insecure"} />
                </Grid>
            </Grid>
        </>
    );
};

// ======================
// CloudstackConnParams
// ======================

export const CloudstackConnParams: React.FC<ConnectionParamProps> = (p) => {
    return (
        <>
            <Grid container spacing={3} pb={1}>
                <Grid size={12}>
                    <FormTextField name={"connParams.endpoint"} label={"Endpoint"} variant={"filled"} required={p.required} />
                    <FormHelperText>{"Endpoint to your Cloudstack API server (e.g. https://cloudstack.example.com:8080)"}</FormHelperText>
                </Grid>
                <Grid size={12}>
                    <FormTextField name={"connParams.apiKey"} label={"API Key"} variant={"filled"} required={p.required} />
                    <FormHelperText>{"API key for the Cloudstack API"}</FormHelperText>
                </Grid>
                <Grid size={12}>
                    <FormTextField name={"connParams.apiSecret"} label={"API Secret"} variant={"filled"} required={p.required} />
                    <FormHelperText>{"API secret for the Cloudstack API"}</FormHelperText>
                </Grid>
                <Grid size={12}>
                    <FormTextField name={"connParams.domain"} label={"Domain"} variant={"filled"} required={false} />
                    <FormHelperText>{"Domain for the Cloudstack API"}</FormHelperText>
                </Grid>
                <Grid size={12}>
                    <FormCheckboxItem label={"Allow Insecure Connection"} name={"connParams.insecure"} />
                </Grid>
            </Grid>
        </>
    );
};

// ======================
// OpenstackConnParams
// ======================

export const OpenstackConnParams: React.FC<ConnectionParamProps> = (p) => {
    return (
        <>
            <Grid container spacing={3}>
                <Grid size={12}>
                    <FormTextField name={"connParams.auth_url"} label={"Keystone Auth URL"} variant={"filled"} required={p.required} />
                    <FormHelperText>{"OpenStack Keystone authentication URL (e.g. https://keystone.example.com:5000)"}</FormHelperText>
                </Grid>
            </Grid>
            <Grid container spacing={3} mt={1}>
                <Grid size={{ xs: 12, sm: 6 }}>
                    <FormTextField name={"connParams.username"} label={"Username"} variant={"filled"} required={false} />
                    <FormHelperText>{"OpenStack username"}</FormHelperText>
                </Grid>
                <Grid size={{ xs: 12, sm: 6 }}>
                    <FormTextField
                        name={"connParams.password"}
                        label={"Password"}
                        variant={"filled"}
                        required={false}
                        id={INTEGRATION_PASSWORD}
                        type={"text"}
                        onFocus={(e) => {
                            document.getElementById(INTEGRATION_PASSWORD).setAttribute("type", "password");
                        }}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={3} mt={1}>
                <Grid size={{ xs: 12, sm: 6 }}>
                    <FormTextField name={"connParams.user_domain_name"} label={"User Domain Name"} variant={"filled"} required={false} />
                    <FormHelperText>{"OpenStack user domain name"}</FormHelperText>
                </Grid>
                <Grid size={{ xs: 12, sm: 6 }}>
                    <FormTextField name={"connParams.project_name"} label={"Project Name"} variant={"filled"} required={false} />
                    <FormHelperText>{"OpenStack project name"}</FormHelperText>
                </Grid>
            </Grid>
            <Grid container spacing={3} mt={1}>
                <Grid size={12}>
                    <FormTextField name={"connParams.region"} label={"Region"} variant={"filled"} required={false} />
                    <FormHelperText>{"OpenStack region name (optional)"}</FormHelperText>
                </Grid>
            </Grid>
            <Grid container spacing={3} mt={1}>
                <Grid size={12}>
                    <FormCheckboxItem label={"Allow Insecure Connection"} name={"connParams.insecure"} />
                    <FormHelperText>{"Skip SSL certificate validation"}</FormHelperText>
                </Grid>
            </Grid>
        </>
    );
};

// ======================
// SelectEditCredentials
// ======================

interface SelectEditCredentialsProps {}

export const SelectEditCredentials: React.FC<SelectEditCredentialsProps> = observer((p) => {
    return (
        <>
            <Typography variant={"body1"}></Typography>
            <FormRadioGroup
                label={"Do you want to edit the integration credentials?"}
                name={"editCredentials"}
                options={[
                    { label: "No", value: "no" },
                    { label: "Yes", value: "yes" },
                ]}
            />
        </>
    );
});

// ======================
// VerifyIntegrationConnection
// ======================

interface VerifyIntegrationConnectionProps {}

export const VerifyIntegrationConnection: React.FC<VerifyIntegrationConnectionProps> = observer((p) => {
    const apis = useGrpcApiStore();
    const projectId = useCurrentProjectID();
    return (
        <>
            <FormAsyncAutocompleteField
                label={"Deployment"}
                name={`deploymentId`}
                required={false}
                valueGetter={(value: GalaxyMigrateDeploymentInfo.AsObject) => value.deployment.systemId}
                dataFetcher={async () => {
                    const req = new ListGalaxyMigrateDeployments.Request()
                        .setProjectId(projectId)
                        .setOnlyConnected(true)
                        .setExcludeHelperNodes(false)
                        .setPageParams(new PagerParams().setPerPage(10000));
                    const res = await apis.deploymentService.listGalaxyMigrateDeployments(req, null);
                    return res.toObject();
                }}
                isOptionEqualToValue={(option: GalaxyMigrateDeploymentInfo.AsObject, value: GalaxyMigrateDeploymentInfo.AsObject) => {
                    return option.deployment.systemId === value.deployment.systemId;
                }}
                autoSelect={false}
                getOptionLabel={(option: GalaxyMigrateDeploymentInfo.AsObject) => option.deployment.systemName}
            />
        </>
    );
});

export const renderConnectionParamFields = (type: IntegrationModule, required: boolean): React.ReactElement => {
    if (type === IntegrationModule.AWS) {
        return <AwsConnectionParams required={required} />;
    } else if (type === IntegrationModule.AZURE) {
        return <AzureConnectionParams required={required} />;
    } else if (type === IntegrationModule.DGS) {
        return <DGSConnectionParams required={required} />;
    } else if (type === IntegrationModule.PURE || type === IntegrationModule.FC_PURE) {
        return <PureConnectionParams required={required} />;
    } else if (type === IntegrationModule.NETAPP || type === IntegrationModule.AWS_FSX_NETAPP) {
        return <NetappConnectionParams required={required} />;
    } else if (type === IntegrationModule.POWERSTORE || type === IntegrationModule.FC_POWERSTORE) {
        return <PowerStoreConnectionParams required={required} />;
    } else if (type === IntegrationModule.FC_POWERMAX) {
        return <PowerMaxConnectionParams required={required} />;
    } else if (type === IntegrationModule.GCP) {
        return <GcpConnectionParams required={required} />;
    } else if (type === IntegrationModule.ORACLE) {
        return <OracleConnectionParams required={required} />;
    } else if (type === IntegrationModule.SILKSDP) {
        return <SilkConnectionParams required={required} />;
    } else if (type === IntegrationModule.DIGITALOCEAN) {
        return <DigitalOceanConnectionParams required={required} />;
    } else if (type === IntegrationModule.POWERFLEX) {
        return <PowerflexConnParams required={required} />;
    } else if (type === IntegrationModule.AZURESAN) {
        return <AzureElasticSanConnectionParams required={required} />;
    } else if (type === IntegrationModule.VMWAREDATASTORE) {
        return <VmwareDatastoreConnParams required={required} />;
    } else if (type === IntegrationModule.NUTANIX) {
        return <NutanixConnParams required={required} />;
    } else if (type === IntegrationModule.OVIRT) {
        return <OvirtConnParams required={required} />;
    } else if (type === IntegrationModule.KUBERNETES) {
        return <KubernetesConnParams required={required} />;
    } else if (type === IntegrationModule.CLOUDSTACK) {
        return <CloudstackConnParams required={required} />;
    } else if (type === IntegrationModule.OPENSTACK) {
        return <OpenstackConnParams required={required} />;
    }
};

export const getConnParamsByModule = (values: FormFieldValues): IntegrationParams.ConnParams => {
    let connParams;
    if (values.module === IntegrationModule.AWS) {
        connParams = setAwsConnParams(values.connParams as AwsFields);
    } else if (values.module === IntegrationModule.AZURE) {
        connParams = setAzureConnParams(values.connParams as AzureFields);
    } else if (values.module === IntegrationModule.DGS) {
        connParams = setDgsConnParams(values.connParams as DGSFields);
    } else if (values.module === IntegrationModule.PURE) {
        connParams = setPureConnParams(values.connParams as PureFields);
    } else if (values.module === IntegrationModule.NETAPP || values.module === IntegrationModule.AWS_FSX_NETAPP) {
        connParams = setNetappConnParams(values.connParams as NetappFields);
    } else if (values.module === IntegrationModule.FC_PURE) {
        connParams = setFcPureConnParams(values.connParams as PureFields);
    } else if (values.module === IntegrationModule.FC_POWERSTORE) {
        connParams = setFcPowerStoreConnParams(values.connParams as PowerStoreFields);
    } else if (values.module === IntegrationModule.POWERSTORE) {
        connParams = setPowerStoreConnParams(values.connParams as PowerStoreFields);
    } else if (values.module === IntegrationModule.FC_POWERMAX) {
        connParams = setPowerMaxConnParams(values.connParams as PowerMaxFields);
    } else if (values.module === IntegrationModule.GCP) {
        connParams = setGcpConnParams(values.connParams as GcpFields);
    } else if (values.module === IntegrationModule.ORACLE) {
        connParams = setOracleConnParams(values.connParams as OracleFields);
    } else if (values.module === IntegrationModule.SILKSDP) {
        connParams = setSilkConnParams(values.connParams as SilkFields);
    } else if (values.module === IntegrationModule.DIGITALOCEAN) {
        connParams = setDigitalOceanConnParams(values.connParams as DigitalOceanFields);
    } else if (values.module === IntegrationModule.POWERFLEX) {
        connParams = setPowerFlexConnParams(values.connParams as PowerFlexFields);
    } else if (values.module === IntegrationModule.AZURESAN) {
        connParams = setAzureElasticSanConnParams(values.connParams as AzureElasticSanFields);
    } else if (values.module === IntegrationModule.VMWAREDATASTORE) {
        connParams = setVmwareDatastoreConnParams(values.connParams as VmwareDatastoreFields);
    } else if (values.module === IntegrationModule.NUTANIX) {
        connParams = setNutanixConnParams(values.connParams as NutanixFields);
    } else if (values.module === IntegrationModule.OVIRT) {
        connParams = setOvirtConnParams(values.connParams as OvirtFields);
    } else if (values.module === IntegrationModule.KUBERNETES) {
        connParams = setKubernetesConnParams(values.connParams as KubernetesFields);
    } else if (values.module === IntegrationModule.CLOUDSTACK) {
        connParams = setCloudstackConnParams(values.connParams as CloudstackFields);
    } else if (values.module === IntegrationModule.OPENSTACK) {
        connParams = setOpenstackConnParams(values.connParams as OpenstackFields);
    }
    return connParams;
};

const setAzureConnParams = (values: AzureFields) => {
    if (values.client && values.secret && values.tenant && values.subscriptionId) {
        return new IntegrationParams.ConnParams().setAzure(
            new IntegrationParams.ConnParams.Azure()
                .setClient(values.client)
                .setTenant(values.tenant)
                .setSecret(values.secret)
                .setSubscriptionId(values.subscriptionId)
        );
    }
    return new IntegrationParams.ConnParams().setAzure(new IntegrationParams.ConnParams.Azure());
};

const setAzureElasticSanConnParams = (values: AzureElasticSanFields) => {
    if (values.client && values.secret && values.tenant && values.sanRg && values.sanName && values.subscriptionId) {
        return new IntegrationParams.ConnParams().setAzuresan(
            new IntegrationParams.ConnParams.AzureElasticSAN()
                .setSanRg(values.sanRg)
                .setSanName(values.sanName)
                .setAzureCreds(
                    new IntegrationParams.ConnParams.Azure()
                        .setClient(values.client)
                        .setSubscriptionId(values.subscriptionId)
                        .setTenant(values.tenant)
                        .setSecret(values.secret)
                )
        );
    }
    return new IntegrationParams.ConnParams().setAzuresan(new IntegrationParams.ConnParams.AzureElasticSAN());
};

const setAwsConnParams = (values: AwsFields) => {
    if (values.accessKey && values.secret) {
        return new IntegrationParams.ConnParams().setAws(
            new IntegrationParams.ConnParams.AWS()
                .setAccessKey(values.accessKey)
                .setSecretAccessKey(values.secret)
                .setRoleArn(values.roleArn)
                .setExternalId(values.externalId)
        );
    }
    return new IntegrationParams.ConnParams().setAws(new IntegrationParams.ConnParams.AWS());
};

const setDgsConnParams = (values: DGSFields) => {
    if (values.username && values.password && values.endpoint) {
        const endpoint = getEndpointFormat(values.endpoint);
        return new IntegrationParams.ConnParams().setDgs(
            new IntegrationParams.ConnParams.DGS().setUsername(values.username).setPassword(values.password).setEndpoint(endpoint)
        );
    }
    return new IntegrationParams.ConnParams().setDgs(new IntegrationParams.ConnParams.DGS());
};

const setPureConnParams = (values: PureFields) => {
    if (values.apiToken && values.managementUri) {
        const endpoint = getEndpointFormat(values.managementUri);

        return new IntegrationParams.ConnParams().setPurecbs(
            new IntegrationParams.ConnParams.PureCBS().setManagementUri(endpoint).setApiToken(values.apiToken)
        );
    }
    return new IntegrationParams.ConnParams().setPurecbs(new IntegrationParams.ConnParams.PureCBS());
};

const setFcPureConnParams = (values: PureFields) => {
    if (values.apiToken && values.managementUri) {
        const endpoint = getEndpointFormat(values.managementUri);

        return new IntegrationParams.ConnParams().setFcPurefa(
            new IntegrationParams.ConnParams.PureCBS().setManagementUri(endpoint).setApiToken(values.apiToken)
        );
    }
    return new IntegrationParams.ConnParams().setFcPurefa(new IntegrationParams.ConnParams.PureCBS());
};

const setNetappConnParams = (values: NetappFields) => {
    if (values.username && values.password && values.endpoint) {
        const endpoint = getEndpointFormat(values.endpoint);
        return new IntegrationParams.ConnParams().setNetapp(
            new IntegrationParams.ConnParams.NetApp().setUsername(values.username).setPassword(values.password).setEndpoint(endpoint)
        );
    }
    return new IntegrationParams.ConnParams().setNetapp(new IntegrationParams.ConnParams.NetApp());
};

const setFcPowerStoreConnParams = (values: PowerStoreFields) => {
    if (values.username && values.password && values.endpoint) {
        const endpoint = getEndpointFormat(values.endpoint);
        return new IntegrationParams.ConnParams().setFcPowerstore(
            new IntegrationParams.ConnParams.EMCPowerStore().setUsername(values.username).setPassword(values.password).setEndpoint(endpoint)
        );
    }
    return new IntegrationParams.ConnParams().setFcPowerstore(new IntegrationParams.ConnParams.EMCPowerStore());
};

const setPowerStoreConnParams = (values: PowerStoreFields) => {
    if (values.username && values.password && values.endpoint) {
        const endpoint = getEndpointFormat(values.endpoint);
        return new IntegrationParams.ConnParams().setPowerstore(
            new IntegrationParams.ConnParams.EMCPowerStore().setUsername(values.username).setPassword(values.password).setEndpoint(endpoint)
        );
    }
    return new IntegrationParams.ConnParams().setPowerstore(new IntegrationParams.ConnParams.EMCPowerStore());
};

const setPowerMaxConnParams = (values: PowerMaxFields) => {
    if (values.username && values.password && values.endpoint && values.arrayId) {
        let endpoint = getEndpointFormat(values.endpoint);
        if (endpoint.split(":").length === 2) {
            endpoint = `${endpoint}:8443`;
        }
        return new IntegrationParams.ConnParams().setFcPowermax(
            new IntegrationParams.ConnParams.EMCPowerMax()
                .setArrayid(values.arrayId)
                .setEndpoint(endpoint)
                .setPassword(values.password)
                .setUsername(values.username)
        );
    }
    return new IntegrationParams.ConnParams().setFcPowermax(new IntegrationParams.ConnParams.EMCPowerMax());
};

const setGcpConnParams = (values: GcpFields) => {
    if (values.credentials) {
        return new IntegrationParams.ConnParams().setGcp(new IntegrationParams.ConnParams.GCP().setCredentials(values.credentials));
    }
    return new IntegrationParams.ConnParams().setGcp(new IntegrationParams.ConnParams.GCP());
};

const setOracleConnParams = (values: OracleFields) => {
    if (values.ocid && values.privateKey && values.fingerprint && values.tenancy) {
        return new IntegrationParams.ConnParams().setOracle(
            new IntegrationParams.ConnParams.Oracle()
                .setOcid(values.ocid)
                .setTenancy(values.tenancy)
                .setFingerprint(values.fingerprint)
                .setPrivateKey(values.privateKey)
        );
    }
    return new IntegrationParams.ConnParams().setOracle(new IntegrationParams.ConnParams.Oracle());
};

const setSilkConnParams = (values: SilkFields) => {
    if (values.username && values.password && values.endpoint) {
        const endpoint = getEndpointFormat(values.endpoint);
        return new IntegrationParams.ConnParams().setSilksdp(
            new IntegrationParams.ConnParams.SilkSdp().setUsername(values.username).setPassword(values.password).setEndpoint(endpoint)
        );
    }
    return new IntegrationParams.ConnParams().setSilksdp(new IntegrationParams.ConnParams.SilkSdp());
};

const setDigitalOceanConnParams = (values: DigitalOceanFields) => {
    if (values.apiToken) {
        return new IntegrationParams.ConnParams().setDigitalocean(new IntegrationParams.ConnParams.DigitalOcean().setApiToken(values.apiToken));
    }
    return new IntegrationParams.ConnParams().setDigitalocean(new IntegrationParams.ConnParams.DigitalOcean());
};

const setPowerFlexConnParams = (values: PowerFlexFields) => {
    if (values.username && values.password && values.endpoint) {
        const endpoint = getEndpointFormat(values.endpoint);
        return new IntegrationParams.ConnParams().setPowerflex(
            new IntegrationParams.ConnParams.EMCPowerFlex()
                .setUsername(values.username)
                .setPassword(values.password)
                .setEndpoint(endpoint)
                .setInsecure(!values.validateCert)
        );
    }
    return new IntegrationParams.ConnParams().setPowerflex(new IntegrationParams.ConnParams.EMCPowerFlex());
};

const setVmwareDatastoreConnParams = (values: VmwareDatastoreFields) => {
    if (values.host && values.username && values.password) {
        return new IntegrationParams.ConnParams().setVmwareDatastore(
            new IntegrationParams.ConnParams.VmwareDatastore()
                .setPassword(values.password)
                .setUsername(values.username)
                .setHost(values.host)
                .setInsecure(values.insecure)
        );
    }
    return new IntegrationParams.ConnParams().setVmwareDatastore(new IntegrationParams.ConnParams.VmwareDatastore());
};

const setNutanixConnParams = (values: NutanixFields) => {
    if (values.username && values.password && values.endpoint) {
        const endpoint = getEndpointFormat(values.endpoint);
        return new IntegrationParams.ConnParams().setNutanix(
            new IntegrationParams.ConnParams.Nutanix()
                .setUsername(values.username)
                .setPassword(values.password)
                .setEndpoint(endpoint)
                .setInsecure(!values.validateCert)
        );
    }
    return new IntegrationParams.ConnParams().setNutanix(new IntegrationParams.ConnParams.Nutanix());
};

const setOvirtConnParams = (values: OvirtFields) => {
    if (values.username && values.password && values.host) {
        return new IntegrationParams.ConnParams().setOvirt(
            new IntegrationParams.ConnParams.Ovirt()
                .setUsername(values.username)
                .setPassword(values.password)
                .setInsecure(!values.validateCert)
                .setHost(values.host)
        );
    }
    return new IntegrationParams.ConnParams().setOvirt(new IntegrationParams.ConnParams.Ovirt());
};

const setKubernetesConnParams = (values: KubernetesFields) => {
    return new IntegrationParams.ConnParams().setKubernetes(
        new IntegrationParams.ConnParams.KubernetesCluster()
            .setEndpoint(values.endpoint)
            .setToken(values.token)
            .setCaCert(values.caCert)
            .setInsecure(values.insecure)
            .setClientKey(values.clientKey)
            .setClientCert(values.clientCert)
            .setNamespace(values.namespace)
    );
};

const setCloudstackConnParams = (values: CloudstackFields) => {
    return new IntegrationParams.ConnParams().setCloudstack(
        new IntegrationParams.ConnParams.CloudStack()
            .setEndpoint(values.endpoint)
            .setApiKey(values.apiKey)
            .setApiSecret(values.apiSecret)
            .setDomain(values.domain)
            .setInsecure(values.insecure)
    );
};

const setOpenstackConnParams = (values: OpenstackFields) => {
    return new IntegrationParams.ConnParams().setOpenstack(
        new IntegrationParams.ConnParams.OpenStack()
            .setUsername(values.username)
            .setPassword(values.password)
            .setAuthUrl(values.auth_url)
            .setUserDomainName(values.user_domain_name)
            .setProjectName(values.project_name)
            .setRegion(values.region)
    );
};

const getEndpointFormat = (endpoint: string) => {
    return endpoint.includes(`https://`) || endpoint.includes(`http://`) ? endpoint : `https://${endpoint}`;
};

const getHostDisabledByType = (type: IntegrationModule, hostEnv: DeploymentHostEnvironment) => {
    if (type === IntegrationModule.AZURE && hostEnv.getValue() !== DeploymentHostEnvironment.DeploymentHostEnvironment.AZURE) {
        return true;
    } else if (type === IntegrationModule.AWS && hostEnv.getValue() !== DeploymentHostEnvironment.DeploymentHostEnvironment.AWS) {
        return true;
    } else {
        return false;
    }
};

const getConnParamFieldsEmpty = (fields: ConnParamFields) => {
    return !Object.values(fields).join("");
};
